










































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { Messages } from "@/models/enums/messages.enum";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { productService } from "@/services/product.service";
import { settingsServices } from "@/services/settings.service";
import { ResponsePagination } from "@/models/interface/common.interface";
import { debounceProcess } from "@/helpers/debounce";
import { DataListAccount } from "@/models/interface/product.interface";
import { mapGetters } from "vuex";
import { getauthorities } from "@/helpers/cookie";
export default Vue.extend({
  data() {
    this.getListOfAccount = debounceProcess(this.getListOfAccount, 200);
    return {
      titlemodalOpen: "" as string,
      modalOpen: false as boolean,
      typemodal: "" as string,
      form: this.$form.createForm(this, { name: "setupproduct" }),
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      search: "" as string,
      sort: "" as string,
      direction: "" as string,
      isFormSubmitted: false as boolean,
      loadingcostOfSalesAccountId: false as boolean,
      datalistcostOfSalesAccountId: [] as DataListAccount[],
      loadinginventoryAccountId: false as boolean,
      datalistinventoryAccountId: [] as DataListAccount[],
      loadingexpensePurchaseAccountId: false as boolean,
      datalistexpensePurchaseAccountId: [] as DataListAccount[],
      loadingpurchaseReturnAccountId: false as boolean,
      datalistpurchaseReturnAccountId: [] as DataListAccount[],
      loadingpurchaseDiscountAccountId: false as boolean,
      datalistpurchaseDiscountAccountId: [] as DataListAccount[],
      loadingassetCostAccountId: false as boolean,
      datalistassetCostAccountId: [] as DataListAccount[],
      loadingassetClearingAccountId: false as boolean,
      datalistassetClearingAccountId: [] as DataListAccount[],
      loadingassetDepreciationAccountId: false as boolean,
      datalistassetDepreciationAccountId: [] as DataListAccount[],
      loadingassetAccumulationAccountId: false as boolean,
      datalistassetAccumulationAccountId: [] as DataListAccount[],
      loadingunbilledAccountId: false as boolean,
      datalistunbilledAccountId: [] as DataListAccount[],
      loadingsalesAccountId: false as boolean,
      datalistsalesAccountId: [] as DataListAccount[],
      loadingsalesReturnAccountId: false as boolean,
      datalistsalesReturnAccountId: [] as DataListAccount[],
      loadingsalesDiscountAccountId: false as boolean,
      datalistsalesDiscountAccountId: [] as DataListAccount[],
      loadingJobCostingAccountId: false as boolean,
      datalistjobCostingAccountId: [] as DataListAccount[],
      formRules: {
        name: {
          label: this.$t("lbl_category"),
          name: "Category",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "name",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        jobCostingAccountId: {
          label: this.$t("lbl_job_costing_account"),
          name: "jobCostingAccountId",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "jobCostingAccountId",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        costOfSalesAccountId: {
          label: this.$t("lbl_cost_of_sales_account"),
          name: "costOfSalesAccountId",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "costOfSalesAccountId",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        inventoryAccountId: {
          label: this.$t("lbl_inventory_account"),
          name: "inventoryAccountId",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "inventoryAccountId",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        expensePurchaseAccountId: {
          label: this.$t("lbl_expense_purchase_account"),
          name: "expensePurchaseAccountId",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "expensePurchaseAccountId",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        purchaseReturnAccountId: {
          label: this.$t("lbl_purchase_return_account"),
          name: "purchaseReturnAccountId",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "purchaseReturnAccountId",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        purchaseDiscountAccountId: {
          label: this.$t("lbl_purchase_discount_account"),
          name: "purchaseDiscountAccountId",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "purchaseDiscountAccountId",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        assetCostAccountId: {
          label: this.$t("lbl_asset_cost_account"),
          name: "assetCostAccountId",
          placeholder: "Insert Asset Cost Account",
          decorator: [
            "assetCostAccountId",
            {
              rules: [
                {
                  required: false,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        assetClearingAccountId: {
          label: this.$t("lbl_asset_clearing_account"),
          name: "assetClearingAccountId",
          placeholder: "Insert Asset Clearing Account",
          decorator: [
            "assetClearingAccountId",
            {
              rules: [
                {
                  required: false,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        assetDepreciationAccountId: {
          label: this.$t("lbl_asset_depreciation_account"),
          name: "assetDepreciationAccountId",
          placeholder: "Insert Asset Depreciation Account",
          decorator: [
            "assetDepreciationAccountId",
            {
              rules: [
                {
                  required: false,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        assetAccumulationAccountId: {
          label: this.$t("lbl_asset_accumulation_account"),
          name: "assetAccumulationAccountId",
          placeholder: "Insert Asset Accumulation Account",
          decorator: [
            "assetAccumulationAccountId",
            {
              rules: [
                {
                  required: false,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        unbilledAccountId: {
          label: this.$t("lbl_unbilled_account"),
          name: "unbilledAccountId",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "unbilledAccountId",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        salesAccountId: {
          label: this.$t("lbl_sales_account"),
          name: "salesAccountId",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "salesAccountId",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        salesReturnAccountId: {
          label: this.$t("lbl_sales_return_account"),
          name: "salesReturnAccountId",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "salesReturnAccountId",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        salesDiscountAccountId: {
          label: this.$t("lbl_sales_discount_account"),
          name: "salesDiscountAccountId",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "salesDiscountAccountId",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
      },
      columnsTable: [
        {
          title: this.$t("lbl_product_category"),
          dataIndex: "name",
          key: "productCategories",
          sorter: false,
          width: 150,
        },
      ] as any,
      dataList: {
        data: [],
      },
      authPrivilege: [] as string[],
    };
  },
  computed: {
    ...mapGetters({
      getUserPrivilege: "authStore/GET_USER_PRIVILEGES",
    }),
    hasPrivilegeCreateProduct(): boolean {
      return this.getUserPrivilege.find(
        (x) => x.key === "product-category" && x.privilege.create
      );
    },
  },
  mounted() {
    this.setAuthorities();
    this.getListOfProductCategory();
    this.getListOfAccount("", "");
  },
  methods: {
    setAuthorities(): void {
      const auth = getauthorities();
      auth.forEach(dataAuth => {
        if(dataAuth.actions?.length && dataAuth?.name === "product-category") {
          this.authPrivilege = dataAuth.value;
          this.columnsTable.push(
            {
              title: this.$t("lbl_action"),
              dataIndex: "operation",
              align: "center",
              fixed: "right",
              width: 120,
              scopedSlots: { customRender: "operation" },
              button: dataAuth.actions,
            },
          );
        }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    reponseSearchInput(response): void {
      if (response) this.search = "name~*" + response + "*";
      else this.search = "";
      this.getListOfProductCategory();
    },
    createNew(): void {
      this.modalOpen = true;
      this.typemodal = "create";
      this.titlemodalOpen = this.$t("lbl_create_product_category").toString();
      this.form.resetFields();
    },
    handleCancel(): void {
      this.modalOpen = false;
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListOfProductCategory();
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.getListOfProductCategory();
    },
    getListOfProductCategory(): void {
      let params = {
        limit: this.limit,
        page: this.page - 1,
        sorts: "createdDate:desc",
      } as RequestQueryParamsModel;
      if (this.search) params.search = this.search;
      if (this.direction) params.sorts = this.sort;
      productService
        .listProductCategory(params)
        .then((res: any) => {
          res.data.map((dataObject, index) => (dataObject.key = index));
          this.dataList = res;
        })
        .finally(() => (this.loadingTable = false));
    },
    getListOfAccount(value, key): void {
      let params = {
        limit: 10,
        page: 0,
      } as RequestQueryParamsModel;
      if (value) {
        params.search = "code~*" + value + "*_OR_description~*" + value + "*";
      }
      if (key) {
        switch (key) {
          case "costOfSalesAccountId":
            this.loadingcostOfSalesAccountId = true;
            break;
          case "inventoryAccountId":
            this.loadinginventoryAccountId = true;
            break;
          case "expensePurchaseAccountId":
            this.loadingexpensePurchaseAccountId = true;
            break;
          case "purchaseReturnAccountId":
            this.loadingpurchaseReturnAccountId = true;
            break;
          case "purchaseDiscountAccountId":
            this.loadingpurchaseDiscountAccountId = true;
            break;
          case "assetCostAccountId":
            this.loadingassetCostAccountId = true;
            break;
          case "assetClearingAccountId":
            this.loadingassetClearingAccountId = true;
            break;
          case "assetDepreciationAccountId":
            this.loadingassetDepreciationAccountId = true;
            break;
          case "assetAccumulationAccountId":
            this.loadingassetAccumulationAccountId = true;
            break;
          case "unbilledAccountId":
            this.loadingunbilledAccountId = true;
            break;
          case "salesAccountId":
            this.loadingsalesAccountId = true;
            break;
          case "salesReturnAccountId":
            this.loadingsalesReturnAccountId = true;
            break;
          case "salesDiscountAccountId":
            this.loadingsalesDiscountAccountId = true;
            break;
          case "jobCostingAccountId":
            this.loadingJobCostingAccountId = true;
            break;
          default:
            break;
        }
      }
      settingsServices.listOfCoa(params, "").then((res: any) => {
        if (key) {
          switch (key) {
            case "costOfSalesAccountId":
              this.loadingcostOfSalesAccountId = false;
              this.datalistcostOfSalesAccountId = res.data;
              break;
            case "inventoryAccountId":
              this.loadinginventoryAccountId = false;
              this.datalistinventoryAccountId = res.data;
              break;
            case "expensePurchaseAccountId":
              this.loadingexpensePurchaseAccountId = false;
              this.datalistexpensePurchaseAccountId = res.data;
              break;
            case "purchaseReturnAccountId":
              this.loadingpurchaseReturnAccountId = false;
              this.datalistpurchaseReturnAccountId = res.data;
              break;
            case "purchaseDiscountAccountId":
              this.loadingpurchaseDiscountAccountId = false;
              this.datalistpurchaseDiscountAccountId = res.data;
              break;
            case "assetCostAccountId":
              this.loadingassetCostAccountId = false;
              this.datalistassetCostAccountId = res.data;
              break;
            case "assetClearingAccountId":
              this.loadingassetClearingAccountId = false;
              this.datalistassetClearingAccountId = res.data;
              break;
            case "assetDepreciationAccountId":
              this.loadingassetDepreciationAccountId = false;
              this.datalistassetDepreciationAccountId = res.data;
              break;
            case "assetAccumulationAccountId":
              this.loadingassetAccumulationAccountId = false;
              this.datalistassetAccumulationAccountId = res.data;
              break;
            case "unbilledAccountId":
              this.loadingunbilledAccountId = false;
              this.datalistunbilledAccountId = res.data;
              break;
            case "salesAccountId":
              this.loadingsalesAccountId = false;
              this.datalistsalesAccountId = res.data;
              break;
            case "salesReturnAccountId":
              this.loadingsalesReturnAccountId = false;
              this.datalistsalesReturnAccountId = res.data;
              break;
            case "salesDiscountAccountId":
              this.loadingsalesDiscountAccountId = false;
              this.datalistsalesDiscountAccountId = res.data;
              break;
            case "jobCostingAccountId":
              this.loadingJobCostingAccountId = false;
              this.datalistjobCostingAccountId = res.data;
              break;
            default:
              break;
          }
        } else {
          this.datalistcostOfSalesAccountId = res.data;
          this.datalistinventoryAccountId = res.data;
          this.datalistexpensePurchaseAccountId = res.data;
          this.datalistpurchaseReturnAccountId = res.data;
          this.datalistpurchaseDiscountAccountId = res.data;
          this.datalistassetCostAccountId = res.data;
          this.datalistassetClearingAccountId = res.data;
          this.datalistassetDepreciationAccountId = res.data;
          this.datalistassetAccumulationAccountId = res.data;
          this.datalistunbilledAccountId = res.data;
          this.datalistsalesAccountId = res.data;
          this.datalistsalesReturnAccountId = res.data;
          this.datalistsalesDiscountAccountId = res.data;
          this.datalistjobCostingAccountId = res.data;
        }
      });
    },
    ResetFilter(getlist: boolean): void {
      this.direction = "";
      this.search = "";
      if (getlist) this.getListOfProductCategory();
    },
    reponseEditTable(response): void {
      this.typemodal = "edit";
      this.titlemodalOpen = this.$t("lbl_edit_product_category").toString();
      this.dataDetail = "/" + response.data.id;
      this.ResetFilter(false);
      this.modalOpen = true;
      this.checkDropdownValue(response);
      const idtimeout = setTimeout(() => {
        this.form.setFieldsValue({
          name: response.data.name,
          costOfSalesAccountId: response.data.costOfSalesAccountId,
          inventoryAccountId: response.data.inventoryAccountId,
          jobCostingAccountId: response.data.jobCostingAccountId,
          expensePurchaseAccountId: response.data.expensePurchaseAccountId,
          purchaseReturnAccountId: response.data.purchaseReturnAccountId,
          purchaseDiscountAccountId: response.data.purchaseDiscountAccountId,
          assetCostAccountId: response.data.assetCostAccountId
            ? response.data.assetCostAccountId
            : "",
          assetClearingAccountId: response.data.assetClearingAccountId
            ? response.data.assetClearingAccountId
            : "",
          assetDepreciationAccountId: response.data.assetDepreciationAccountId
            ? response.data.assetDepreciationAccountId
            : "",
          assetAccumulationAccountId: response.data.assetAccumulationAccountId
            ? response.data.assetAccumulationAccountId
            : "",
          unbilledAccountId: response.data.unbilledAccountId,
          salesAccountId: response.data.salesAccountId,
          salesReturnAccountId: response.data.salesReturnAccountId,
          salesDiscountAccountId: response.data.salesDiscountAccountId,
        });
        clearTimeout(idtimeout);
      }, 200);
    },
    checkDropdownValue(response) {
      let listArrayData = [
        "costOfSalesAccountId",
        "inventoryAccountId",
        "jobCostingAccountId",
        "expensePurchaseAccountId",
        "purchaseReturnAccountId",
        "purchaseDiscountAccountId",
        "assetCostAccountId",
        "assetClearingAccountId",
        "assetDepreciationAccountId",
        "assetAccumulationAccountId",
        "unbilledAccountId",
        "salesAccountId",
        "salesReturnAccountId",
        "salesDiscountAccountId",
      ];
      listArrayData.forEach((item, index) => {
        for (const [key, value] of Object.entries(response.data)) {
          if (key === item) {
            if (
              this[`datalist${item}`].findIndex((item) => item.id === value) ===
                -1 &&
              value
            )
              this[`datalist${item}`].push({
                id: value,
                description: response.data[key.replace("Id", "Name")],
              });
          }
        }
      });
    },
    createProductCategory(values): void {
      productService
        .createProductCategory(values)
        .then((res) => {
          if (res) {
            this.modalOpen = false;
            this.getListOfProductCategory();
            this.form.resetFields();
            this.$message.success(this.$t("notif_create_success").toString());
          }
        })
        .finally(() => (this.isFormSubmitted = false));
    },
    updateProductCategory(values): void {
      productService
        .updateProductCategory(values, this.dataDetail)
        .then((res) => {
          if (res) {
            this.modalOpen = false;
            this.getListOfProductCategory();
            this.form.resetFields();
            this.$message.success(Messages.UPDATE_SUCCESS);
          }
        })
        .finally(() => (this.isFormSubmitted = false));
    },
    submitForm(e, type: "create" | "edit"): void {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.isFormSubmitted = true;
          values["assetCostAccountId"] = values["assetCostAccountId"]
            ? values["assetCostAccountId"]
            : null;
          values["assetClearingAccountId"] = values["assetClearingAccountId"]
            ? values["assetClearingAccountId"]
            : null;
          values["assetDepreciationAccountId"] = values[
            "assetDepreciationAccountId"
          ]
            ? values["assetDepreciationAccountId"]
            : null;
          values["assetAccumulationAccountId"] = values[
            "assetAccumulationAccountId"
          ]
            ? values["assetAccumulationAccountId"]
            : null;
          if (type === "create") {
            this.createProductCategory(values);
          } else {
            this.updateProductCategory(values);
          }
        } else {
          this.$notification["error"]({
            message: this.$t("lbl_error_title").toString(),
            description: this.$t("lbl_form_is_mandatory").toString(),
          });
        }
      });
    },
  },
});
